import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'

import './header.css'

export default function Header() {
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      )
    return (
      <div className="header-content">
        <div className="header-brand">
          <Link to={`/`}>
            <h1 className="site-title">{data.site.siteMetadata.title}</h1>
          </Link>
        </div>
        <nav className="header-nav">
          <a href="mailto:leland.riordan@gmail.com" target="_blank" rel="noreferrer" className="header-nav-link">
            {/* <FontAwesomeIcon icon={faEnvelope} fixedWidth /> */}
            Contact
          </a>
          <a href="https://drive.google.com/file/d/1rVGUI3H6NIayHp9hrcfGnfkxJVefX9iw/view?usp=sharing" target="_blank" rel="noreferrer" className="header-nav-link">
            {/* <FontAwesomeIcon icon={faEnvelope} fixedWidth /> */}
            Resume
          </a>
          <a href="https://www.linkedin.com/in/lelandriordan/" target="_blank" rel="noreferrer" className="header-nav-link">
            LinkedIn
          </a>
          {/* <a href="https://www.linkedin.com/in/lelandriordan/" target="_blank" rel="noreferrer" className="header-nav-link">
            Github
          </a> */}
        </nav>
      </div>
    )
}
