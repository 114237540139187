import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import './footer.css'

export default function Footer() {
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      )
    return (
        <div className="footer-content">
            <p>Copyright &copy; 2022 by <Link to={`/`}>{data.site.siteMetadata.title}</Link></p>
            <p>Hand built with React, Gatsby and Firebase.</p>
        </div>
    )
}
