import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import './citi-velocity.css'

export default function Emily() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Emily</title>
      </Helmet>
        <h1>I love my beautiful girlfriend Emily!</h1>
    </Layout>
  )
}
